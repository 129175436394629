import './App.scss';
import Homepage from './components/homepage/homepage';

function App() {
  return (
    <>
      <Homepage/>
    </>
  );
}

export default App;
